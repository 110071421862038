<h1>features</h1>

<ul class="indented">
  <li>Attractive public site with easy to use online administration of content</li>
  <li>Efficient page caching for improved performance</li>
  <li>Unique slide-shows supported for each page</li>
  <li>Cover-flow style photo albums with shadowboxes to display full image</li>
  <li>Support to upload sermons, bulletins, and newsletters</li>
  <li>Automatically generated RSS and Podcast feeds for sermons, bulletins, and newsletters</li>
  <li>Integration with Google Calendar allowing subscription to calendar of events</li>
  <li>Implemented in Ruby on Rails</li>
</ul>

<h1>screenshots</h1>

<div style="text-align: center; margin-top: 50px;">
  <ng-container *ngFor="let image of album; let i=index">
    <br *ngIf="i % 3 === 0 && i > 1">
    <img class="clickable" style="vertical-align:top" [ngStyle]="{'margin-left' : i % 3 === 0 ? 'default' : '-30px'}"
      [src]="image.thumb" (click)="open(i)" />
  </ng-container>
</div>

