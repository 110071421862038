import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ChristcentralComponent } from './project/christcentral/christcentral.component';
import { CisoComponent } from './project/ciso/ciso.component';
import { CommendablekidsComponent } from './project/commendablekids/commendablekids.component';
import { IbmComponent } from './project/ibm/ibm.component';
import { IntouchgpsComponent } from './project/intouchgps/intouchgps.component';
import { PeepnoteComponent } from './project/peepnote/peepnote.component';
import { ProjectComponent } from './project/project.component';
import { RrrComponent } from './project/rrr/rrr.component';
import { SeminoleComponent } from './project/seminole/seminole.component';
import { StfrancisComponent } from './project/stfrancis/stfrancis.component';
import { UsicComponent } from './project/usic/usic.component';
import { WombeatComponent } from './project/wombeat/wombeat.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  {
    path: 'project', component: ProjectComponent, 
    children: [
      { path: 'ciso', component: CisoComponent }, 
      { path: 'commendablekids', component: CommendablekidsComponent }, 
      { path: 'christcentral', component: ChristcentralComponent },
      { path: 'ibm', component: IbmComponent }, 
      { path: 'intouchgps', component: IntouchgpsComponent }, 
      { path: 'peepnote', component: PeepnoteComponent }, 
      { path: 'rrr', component: RrrComponent }, 
      { path: 'seminole', component: SeminoleComponent }, 
      { path: 'stfrancis', component: StfrancisComponent }, 
      { path: 'usic', component: UsicComponent }, 
      { path: 'wombeat', component: WombeatComponent }, 
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
