<h1>features</h1>

<ul class="indented">
  <li>Common Angular Framework with many custom components shared by all the web sites</li>
  <li>A json:api compliant Java API utilizing Spring Boot and CRNK</li>
  <li>Single login supporting both application specific users and Active Directory integration</li>
  <li>Excel exports of all listings</li>
  <li>Integration with HERE maps</li>
  <li>Applications run in Docker images on a Kubernetes cluster</li>
  <li>Full CI/CD pipeline including SAST, Lint checking, auto deployment, and testing</li>
</ul>

<h1>screenshots</h1>

<div style="text-align: center; margin-top: 50px;">
  <ng-container *ngFor="let image of album; let i=index">
    <br *ngIf="i % 3 === 0 && i > 1">
    <img class="clickable tilted" style="vertical-align:bottom" [ngStyle]="{'margin-left' : i % 3 === 0 ? 'default' : '-30px'}"
      [src]="image.thumb" (click)="open(i)" />
  </ng-container>
</div>