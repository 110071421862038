import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Lightbox, IAlbum } from 'ngx-lightbox';
import { ProjectData } from '../project.component';

@Component({
  selector: 'app-ciso',
  templateUrl: './ciso.component.html',
  styleUrls: ['./ciso.component.scss']
})
export class CisoComponent implements OnInit {

  @Output() projectData: EventEmitter<ProjectData> = new EventEmitter<ProjectData>();

  album: Array<IAlbum> = [];

  constructor(
    private lightbox: Lightbox
  ) { }

  ngOnInit(): void {
    this.projectData.emit({
      summary: `A non-profit organization that provides children with free swimming
      lessons asked me to build the web site for them.  I implemented a site
      that allowed them to provide the desired information about the
      organization as well as provide discussion forums and photo albums for
      the kids.`,
      projectLogo: 'client_ciso.webp',
    });

    this.album.push(
      {
        thumb: 'assets/images/client_ciso_home_thumb.webp',
        src: 'assets/images/client_ciso_home.webp',
        caption: 'Home Page'
      },
      {
        thumb: 'assets/images/client_ciso_sample_thumb.webp',
        src: 'assets/images/client_ciso_sample.webp',
        caption: 'Typical page'
      },
      {
        thumb: 'assets/images/client_ciso_donate_thumb.webp',
        src: 'assets/images/client_ciso_donate.webp',
        caption: 'Donation page'
      },
    )
  

  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.album, index);
  }


}
