import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProjectData } from '../project.component';

@Component({
  selector: 'app-peepnote',
  templateUrl: './peepnote.component.html',
  styleUrls: ['./peepnote.component.scss']
})
export class PeepnoteComponent implements OnInit {

  @Output() projectData: EventEmitter<ProjectData> = new EventEmitter<ProjectData>();

  constructor() { }

  ngOnInit(): void {
    this.projectData.emit({
      summary: `<a href="http://www.peepnote.com">PeepNote</a> was my entry
          into the <a href="http://r09.railsrumble.com/">2009 Rails Rumble</a>
          competition with my team, <a href="http://r09.railsrumble.com/teams/agile-nomads">Agile Nomads</a>.`,
      projectLogo: 'railsrumble_peepnote.webp',
    });
  }

}
