<h1>features</h1>

<ul class="indented">
  <li>Modernized, attractive site that is responsive to double as a mobile app</li>
  <li>Support to upload sermons, services, and bulletins</li>
  <li>Automatically generates RSS and Podcast feeds for sermons and bulletins</li>
  <li>Integration with Google Calendar allowing subscription to calendar of events</li>
  <li>Implemented in Angular with a Node API for dynamic data</li>
</ul>

<h1>screenshots</h1>

<div style="text-align: center; margin-top: 50px;">
  <ng-container *ngFor="let image of album; let i=index">
    <br *ngIf="i % 3 === 0 && i > 1">
    <img class="clickable tilted" style="vertical-align:bottom" [ngStyle]="{'margin-left' : i % 3 === 0 ? 'default' : '-30px'}"
      [src]="image.thumb" (click)="open(i)" />
  </ng-container>
</div>

