import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Lightbox, IAlbum } from 'ngx-lightbox';
import { ProjectData } from '../project.component';

@Component({
  selector: 'app-christcentral',
  templateUrl: './christcentral.component.html',
  styleUrls: ['./christcentral.component.scss']
})
export class ChristcentralComponent implements OnInit {

  @Output() projectData: EventEmitter<ProjectData> = new EventEmitter<ProjectData>();

  album: Array<IAlbum> = [];

  constructor(
    private lightbox: Lightbox
  ) { }

  ngOnInit(): void {
    this.projectData.emit({
      summary: `In 2016, <a href="/project/seminole">Seminole Presbyterian Church</a> moved locations and changed it's name
        to Christ Central Presbyterian Church.  This required a new branded website.  
        Originally it was built in Ruby on Rails as the predecesor site was, but was
        later migrated to Angular.`,
      projectLogo: 'client_christcentral_logo.webp',
    });  
  
    this.album.push(
      {
        thumb: 'assets/images/client_christcentral_home.webp',
        src: 'assets/images/client_christcentral_home.webp',
        caption: 'Home Page'
      },
      {
        thumb: 'assets/images/client_christcentral_home2.webp',
        src: 'assets/images/client_christcentral_home2.webp',
        caption: 'Home Page'
      },
      {
        thumb: 'assets/images/client_christcentral_normal_page.webp',
        src: 'assets/images/client_christcentral_normal_page.webp',
        caption: 'Typical page'
      },
      {
        thumb: 'assets/images/client_christcentral_sermons.webp',
        src: 'assets/images/client_christcentral_sermons.webp',
        caption: 'Latest sermons page with streaming capability'
      },
      {
        thumb: 'assets/images/client_christcentral_members.webp',
        src: 'assets/images/client_christcentral_members.webp',
        caption: 'Member portal'
      },
      {
        thumb: 'assets/images/client_christcentral_navigation.webp',
        src: 'assets/images/client_christcentral_navigation.webp',
        caption: 'Navigation'
      },
      // {
      //   thumb: 'assets/images/client_christcentral_calendar.webp',
      //   src: 'assets/images/client_christcentral_calendar.webp',
      //   caption: 'Integration of Google Calendar'
      // },
    )
  
  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.album, index);
  }


}
