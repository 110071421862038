import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Lightbox, IAlbum } from 'ngx-lightbox';
import { ProjectData } from '../project.component';

@Component({
  selector: 'app-seminole',
  templateUrl: './seminole.component.html',
  styleUrls: ['./seminole.component.scss']
})
export class SeminoleComponent implements OnInit {

  @Output() projectData: EventEmitter<ProjectData> = new EventEmitter<ProjectData>();

  album: Array<IAlbum> = [];

  constructor(
    private lightbox: Lightbox
  ) { }

  ngOnInit(): void {
    this.projectData.emit({
      summary: `Seminole Presbyterian Church in Tampa, FL had
      a <a href="http://web.archive.org/web/20031121221835/http://seminolepca.org/">very
      dated</a> and simplistic web site using all static HTML pages, written
      by someone no longer with the church.  In 2006, they decided to give
      it a face lift.  As a tech savvy member of the congregation, I
      volunteered to build the new site.  Designed by our
      <a href="http://www.seminolepca.org/toenes">Business
        Administrator</a>, I developed it in PHP originally and then re-wrote 
        it in using Ruby on Rails.`,
      projectLogo: 'client_seminole_pca.webp',
    });  
  
    this.album.push(
      {
        thumb: 'assets/images/client_seminole_home_thumb.webp',
        src: 'assets/images/client_seminole_home.webp',
        caption: 'Home Page'
      },
      {
        thumb: 'assets/images/client_seminole_normal_page_thumb.webp',
        src: 'assets/images/client_seminole_normal_page.webp',
        caption: 'Typical page'
      },
      {
        thumb: 'assets/images/client_seminole_overlay_page_thumb.webp',
        src: 'assets/images/client_seminole_overlay_page.webp',
        caption: 'Page with wider content'
      },
      {
        thumb: 'assets/images/client_seminole_sermons_thumb.webp',
        src: 'assets/images/client_seminole_sermons.webp',
        caption: 'Latest sermons page with streaming capability'
      },
      {
        thumb: 'assets/images/client_seminole_photoalbum_thumb.webp',
        src: 'assets/images/client_seminole_photoalbum.webp',
        caption: 'Photo album with coverflow of images'
      },
      {
        thumb: 'assets/images/client_seminole_photoalbum_shadowbox_thumb.webp',
        src: 'assets/images/client_seminole_photoalbum_shadowbox.webp',
        caption: 'View of image in shadowbox'
      },
      {
        thumb: 'assets/images/client_seminole_calendar_thumb.webp',
        src: 'assets/images/client_seminole_calendar.webp',
        caption: 'Integration of Google Calendar'
      },
      {
        thumb: 'assets/images/client_seminole_admin_page_listing_thumb.webp',
        src: 'assets/images/client_seminole_admin_page_listing.webp',
        caption: 'List of pages for administrator'
      },
      {
        thumb: 'assets/images/client_seminole_admin_page_edit_thumb.webp',
        src: 'assets/images/client_seminole_admin_page_edit.webp',
        caption: 'Page editor with inline HTML editor'
      },
    )
  
  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.album, index);
  }


}
