import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LightboxModule } from 'ngx-lightbox';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChristcentralComponent } from './project/christcentral/christcentral.component';
import { CisoComponent } from './project/ciso/ciso.component';
import { CommendablekidsComponent } from './project/commendablekids/commendablekids.component';
import { HomeComponent } from './home/home.component';
import { IbmComponent } from './project/ibm/ibm.component';
import { IntouchgpsComponent } from './project/intouchgps/intouchgps.component';
import { PeepnoteComponent } from './project/peepnote/peepnote.component';
import { ProjectComponent } from './project/project.component';
import { RrrComponent } from './project/rrr/rrr.component';
import { SeminoleComponent } from './project/seminole/seminole.component';
import { StfrancisComponent } from './project/stfrancis/stfrancis.component';
import { WombeatComponent } from './project/wombeat/wombeat.component';
import { UsicComponent } from './project/usic/usic.component';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    ChristcentralComponent,
    CisoComponent,
    CommendablekidsComponent,
    HomeComponent,
    IbmComponent,
    IntouchgpsComponent,
    PeepnoteComponent,
    ProjectComponent,
    RrrComponent,
    SeminoleComponent,
    StfrancisComponent,
    WombeatComponent,
    UsicComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LightboxModule,
    NgxGoogleAnalyticsModule.forRoot(environment.production ? 'G-19BMZ5RQJZ' : ''),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
