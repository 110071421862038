import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Lightbox, IAlbum } from 'ngx-lightbox';
import { ProjectData } from '../project.component';

@Component({
  selector: 'app-intouchgps',
  templateUrl: './intouchgps.component.html',
  styleUrls: ['./intouchgps.component.scss']
})
export class IntouchgpsComponent implements OnInit {

  @Output() projectData: EventEmitter<ProjectData> = new EventEmitter<ProjectData>();

  album: Array<IAlbum> = [];

  constructor(
    private lightbox: Lightbox
  ) { }

  ngOnInit(): void {
    this.projectData.emit({
      summary: `At InTouchGPS I was the lead developer on a Ruby on Rails application that provided fleet management.
        In addition to leading the development process, I was also directly involved with the development and
        was responsible for many technological advances such as the addition of websockets to provide live updates
        rather than periodic refreshes and also allowed customers to be unaffected by queue processing backlogs.`,
      projectLogo: 'client_intouchgps_logo.webp',
    });  
  
    this.album.push(
      {
        thumb: 'assets/images/client_intouchgps_locations.webp',
        src: 'assets/images/client_intouchgps_locations.webp',
        caption: 'Landing page showing current vehicle locations'
      },
      {
        thumb: 'assets/images/client_intouchgps_dashboard.webp',
        src: 'assets/images/client_intouchgps_dashboard.webp',
        caption: 'Dashboard of various metrics'
      },
      {
        thumb: 'assets/images/client_intouchgps_report_activity.webp',
        src: 'assets/images/client_intouchgps_report_activity.webp',
        caption: 'Device Activty Report'
      },
      {
        thumb: 'assets/images/client_intouchgps_behavior_account.webp',
        src: 'assets/images/client_intouchgps_behavior_account.webp',
        caption: 'Behavior of all vehicles in an account'
      },
      {
        thumb: 'assets/images/client_intouchgps_behavior_vehicle.webp',
        src: 'assets/images/client_intouchgps_behavior_vehicle.webp',
        caption: 'Behavior of a specific vehicle'
      },
      {
        thumb: 'assets/images/client_intouchgps_maintenance.webp',
        src: 'assets/images/client_intouchgps_maintenance.webp',
        caption: 'Track Vehicle Maintenance'
      },
      {
        thumb: 'assets/images/client_intouchgps_diagnostics.webp',
        src: 'assets/images/client_intouchgps_diagnostics.webp',
        caption: 'Vehicle Diagnostic Codes'
      },
      {
        thumb: 'assets/images/client_intouchgps_trends.webp',
        src: 'assets/images/client_intouchgps_trends.webp',
        caption: 'Trends'
      },
    )
  
  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.album, index);
  }


}
