<h1>features</h1>

<ul class="indented">
  <li>Collection of latest article that are prioritized, tagged, and
	archived</li>
  <li>Full featured administration section to maintain all site
	data</li>
  <li>Complete CMS tool including drag-and-drop editing</li>
  <li>Easy tagging</li>
  <li>Bookmarklet provided to easily add news of interest</li>
  <li>Efficient page caching for improved performance</li>
  <li>Implemented in Ruby on Rails</li>
</ul>

<h1>screenshots</h1>

<div style="text-align: center; margin-top: 50px;">
  <ng-container *ngFor="let image of album; let i=index" >
    <br *ngIf="i % 3 === 0 && i > 1">
    <img class="clickable" style="vertical-align:top" [ngStyle]="{'margin-left' : i % 3 === 0 ? 'default' : '-30px'}" [src]="image.thumb" (click)="open(i)" />
  </ng-container>
</div>