import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'portfolio';
  production = environment.production;

  constructor(
    private router: Router,
  ) {
    console.clear();

        // Matomo
        const _paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function () {
          var u = "//analytics.pothoven.net/";
          _paq.push(['setTrackerUrl', u + 'matomo.php']);
          _paq.push(['setSiteId', '6']);
          var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
          g.async = true; g.src = u + 'matomo.js'; s.parentNode?.insertBefore(g, s);
        })();
    
        router.events.subscribe(
          (event) => {
            if (event instanceof NavigationEnd) {
              const routerEvent = event as NavigationEnd;
              _paq.push(['setCustomUrl', routerEvent.url]);
              _paq.push(['setDocumentTitle', this.title]);
              _paq.push(['trackPageView']);
            }
          }
        );
    
  }
}
