<h1>features</h1>

<ul class="indented">
  <li>View entire fleet of vehicle loctions and statuses</li>
  <li>Innovative display such as locations icons that displayed speed, direction, and stop duration at a glance</li>
  <li>Utilized WebSockets for live updates</li>
  <li>Comprehensive metrics and reporting</li>
  <li>Detailed history of vehicle activities</li>
  <li>Custom geofences with entry/exit detection</li>
  <li>Integration with SmartWitness cameras</li>
  <li>Driver scorecards</li>
  <li>Track vehicle maintence for the fleet</li>
  <li>Vehicle reservations</li>
  <li>Mobile Apps</li>
  <li>Realtime SMS alerts</li>
  <li>Much more</li>
</ul>

<h1>screenshots</h1>

<div style="text-align: center; margin-top: 50px;">
  <ng-container *ngFor="let image of album; let i=index">
    <br *ngIf="i % 3 === 0 && i > 1">
    <img class="clickable tilted" style="vertical-align:bottom"
      [ngStyle]="{'margin-left' : i % 3 === 0 ? 'default' : '-30px'}" [src]="image.thumb" (click)="open(i)" />
  </ng-container>
</div>

<h1>videos</h1>

<h3>Example of live updates via WebSockets</h3>
<div style="text-align: center;">
  <div id="media">
    <video width="640" autoplay loop="true">
      <source src="/assets/project/intouchgps/SunshineThrift.mp4" type="video/mp4">
    </video>
  </div>
</div>

<h3>Example of SmartWitness camera integration</h3>
<div style="text-align: center;">
  <div id="media">
    <video width="640" autoplay loop="true">
      <source src="/assets/project/intouchgps/Viewing pictures with FS cropped.mp4" type="video/mp4">
    </video>
  </div>
</div>