import { Component, EventEmitter, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

// Project data rendered by the common project component
export type ProjectData = { summary: string, projectLogo: string } | null;

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  // Navigate back to home page when ESC pressed
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.router.navigate(['']);
  }

  private projectDataSubscription: any;
  private projectDataSubject = new BehaviorSubject<ProjectData>(null);
  public projectData = this.projectDataSubject.asObservable();
  public ready = false;

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }


  onActivate(component: any) {
    if (component.projectData instanceof EventEmitter) {
      this.projectDataSubscription = component.projectData.subscribe((projectData: ProjectData) => {
        this.projectDataSubject.next(projectData);
        this.ready = true;
        // Since this happens after the projectData is needed in the masthead div, Angular complains
        // with a ExpressionChangedAfterItHasBeenCheckedError and doesn't render the title.
        // Telling the router to navigate back the page (without actually going anywhere) will
        // cause it to re-evaluate the title and then work.
        this.router.navigate([this.router.url]);
        window.scrollTo(0, 0);
      });
    }
  }

  onDeactivate() {
    if (this.projectDataSubscription)
      this.projectDataSubscription.unsubscribe();
    // Reset the title
    this.projectDataSubject.next(null);
  }

}
