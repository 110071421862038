<h1>features</h1>

<ul class="indented">
  <li>Simple, clean interface with obvious navigation</li>
  <li>Supports online donations through Acceptive and PayPal</li>
  <li>Themed integration with several 3rd party applications such
	as <a href="https://www.vbulletin.com">vBulletin</a>, <a href="https://gallery.menalto.com/">Gallery</a>, <a href="https://webcalendar.sourceforge.net">WebCalendar</a>,
	and <a href="www.squirrelmail.org">SquirrelMail</a></li>
  <li>Used common XSL template for page layout with page content stored
	in XML</li>
  <li>Revolving swimming safety facts on top of page</li>
</ul>

<h1>screenshots</h1>

<div style="text-align: center; margin-top: 50px;">
  <ng-container *ngFor="let image of album; let i=index">
    <img class="clickable" [ngStyle]="{'margin-left' : i > 0 ? '-30px' : 'default'}"
      [src]="image.thumb" (click)="open(i)" />
  </ng-container>
</div>