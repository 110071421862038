import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Lightbox, IAlbum } from 'ngx-lightbox';
import { ProjectData } from '../project.component';

@Component({
  selector: 'app-wombeat',
  templateUrl: './wombeat.component.html',
  styleUrls: ['./wombeat.component.scss']
})
export class WombeatComponent implements OnInit {

  @Output() projectData: EventEmitter<ProjectData> = new EventEmitter<ProjectData>();

  album: Array<IAlbum> = [];

  constructor(
    private lightbox: Lightbox
  ) { }

  ngOnInit(): void {
    this.projectData.emit({
      summary: `Through my partnership in <a href="http://www.agilenomads.com">Agile
      Nomads</a>, I've worked with WOMbeat! to provide some technical
      direction and development services for this growing web venture.`,
      projectLogo: 'client_wombeat_logo.webp',
    });

    this.album.push(
      {
        thumb: 'assets/images/client_wombeat_listing_thumb.webp',
        src: 'assets/images/client_wombeat_listing.webp',
        caption: 'Specific listing view.  Administrators now have option to edit'
      },
      {
        thumb: 'assets/images/client_wombeat_edit_listing_thumb.webp',
        src: 'assets/images/client_wombeat_edit_listing.webp',
        caption: 'The edit form for a specific listing.'
      },
      {
        thumb: 'assets/images/client_wombeat_contact_thumb.webp',
        src: 'assets/images/client_wombeat_contact.webp',
        caption: 'Contact forms.  Multiple contact functions available based on dropdown selection.'
      },
    );

  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.album, index);
  }


}
