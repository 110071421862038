<h1>features</h1>

<ul class="indented">
  <li>Attractive public site with easy to use online
    administration</li>
  <li>Supports multiple images per animal with automatic thumbnail
    generation</li>
  <li>Automatic email notifications to staff of adoption requests and
    other online forms</li>
  <li>Immediate online updates when animal status changes (ex. when an
    adoption request is submitted, the online status is changes to
    'adoption pending' to prevent further requests)</li>
  <li>Generation of required PDFs for public cage cards and health
    records</li>
  <li>Full health record maintenance</li>
  <li>Complete record of all animals and adoptions - past and
    present</li>
  <li>Easy to update calendar of events with address
    verification and map generation</li>
  <li>Efficient page caching for improved performance</li>
  <li>Complete page content editing</li>
  <li>Implemented in Ruby on Rails</li>
</ul>

<h1>screenshots</h1>

<div style="text-align: center; margin-top: 50px;">
  <ng-container *ngFor="let image of album; let i=index" >
    <br *ngIf="i % 3 === 0 && i > 1">
    <img class="clickable" style="vertical-align:top" [ngStyle]="{'margin-left' : i % 3 === 0 ? 'default' : '-30px'}" [src]="image.thumb" (click)="open(i)" />
  </ng-container>
</div>

<div style="text-align: center; margin-top: 50px;">
  <a href="/project/stfrancis/Cage Card for Lucy.pdf"><img
      src="/assets/images/client_st_francis_Cage Card for Lucy_thumb.webp" title="Cage Card" /></a>
  <a href="/project/stfrancis/Cat Health Record for Lucy.pdf"><img
      src="/assets/images/client_st_francis_Cat Health Record for Lucy_thumb.webp" title="Health Record" /></a>
</div>