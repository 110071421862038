import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  marriedYears: number;
  currentJobYears: number;

  constructor(
    private router: Router,
    private renderer: Renderer2
  ) {
    this.marriedYears = this.years_since(new Date(Date.parse('1993-07-24')));
    this.currentJobYears = this.years_since(new Date(Date.parse('2019-05-28')));
  }

  ngOnInit(): void {
  }

  years_since(startDate: Date) {
    const ms = (new Date()).getTime() - startDate.getTime();
    const date = new Date(ms);
    return Math.abs(date.getUTCFullYear() - 1970);
  }

  moveToTop(event: any) {
    const darkBackgroundElement = document.getElementById('darkenBackground');
    if (darkBackgroundElement) {
      darkBackgroundElement.style.display = 'block';
    }
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      this.renderer.setAttribute(element, 'class', 'selected');
      setTimeout(() => {
        this.router.navigate(['project', element.id]);
      }, 500);
    }
    return false;
  }
}
