import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Lightbox, IAlbum } from 'ngx-lightbox';
import { ProjectData } from '../project.component';

@Component({
  selector: 'app-rrr',
  templateUrl: './rrr.component.html',
  styleUrls: ['./rrr.component.scss']
})
export class RrrComponent implements OnInit {

  @Output() projectData: EventEmitter<ProjectData> = new EventEmitter<ProjectData>();

  album: Array<IAlbum> = [];

  constructor(
    private lightbox: Lightbox
  ) {
  }

  ngOnInit(): void {
    this.projectData.emit({
      summary: `My friend, <a href="http://brianburridge.com">Brian Burridge</a>, had
      an idea that there might be a market for a web site providing
      a <a href="http://drudgereport.com/">Drudge Report</a> style view of
      news and information published for both Ruby and Rails
      developers. So under our partnership, <a href="http://www.agilenomads.com">Agile Nomads</a>,
      I provided him with the infrastructure to maintain such a site.`,
      projectLogo: 'client_ruby_rail_review.webp',
    });

    this.album.push(
      {
        thumb: 'assets/images/client_rrr_home_thumb.webp',
        src: 'assets/images/client_rrr_home.webp',
        caption: 'Home Page'
      },
      {
        thumb: 'assets/images/client_rrr_popout_feedback_thumb.webp',
        src: 'assets/images/client_rrr_popout_feedback.webp',
        caption: 'Pop-out panel for user feedback'
      },
      {
        thumb: 'assets/images/client_rrr_archive_thumb.webp',
        src: 'assets/images/client_rrr_archive.webp',
        caption: 'Full archive or past articles by date, name, or tag'
      },
      {
        thumb: 'assets/images/client_rrr_admin_panel_thumb.webp',
        src: 'assets/images/client_rrr_admin_panel.webp',
        caption: 'Administration console'
      },
      {
        thumb: 'assets/images/client_rrr_admin_drag_layout_thumb.webp',
        src: 'assets/images/client_rrr_admin_drag_layout.webp',
        caption: 'Drag-and-drop layout tool'
      },
      {
        thumb: 'assets/images/client_rrr_admin_article_maint_thumb.webp',
        src: 'assets/images/client_rrr_admin_article_maint.webp',
        caption: 'Maintenance of all DB records (articles shown here)'
      },
      {
        thumb: 'assets/images/client_rrr_admin_article_edit_thumb.webp',
        src: 'assets/images/client_rrr_admin_article_edit.webp',
        caption: 'Article edit screen (clicking tag in list adds to edit field, typing new tag adds to list)'
      },
      {
        thumb: 'assets/images/client_rrr_admin_post_edit_thumb.webp',
        src: 'assets/images/client_rrr_admin_post_edit.webp',
        caption: 'Inline HTML editor for RRR supplied posts'
      },
      {
        thumb: 'assets/images/client_rrr_bookmarklet_thumb.webp',
        src: 'assets/images/client_rrr_bookmarklet.webp',
        caption: 'Bookmarklet for adding articles'
      },
    )
    
  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.album, index);
  }

}
