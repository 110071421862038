import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Lightbox, IAlbum } from 'ngx-lightbox';
import { ProjectData } from '../project.component';

@Component({
  selector: 'app-ibm',
  templateUrl: './ibm.component.html',
  styleUrls: ['./ibm.component.scss']
})
export class IbmComponent implements OnInit {

  @Output() projectData: EventEmitter<ProjectData> = new EventEmitter<ProjectData>();

  expediteAlbum: Array<IAlbum> = [];
  diAlbum: Array<IAlbum> = [];
  biAlbum: Array<IAlbum> = [];
  fmAlbum: Array<IAlbum> = [];
  srmAlbum: Array<IAlbum> = [];
  srmMobileAlbum: Array<IAlbum> = [];
  ibmExceptionalAlbum: Array<IAlbum> = [];
  ibmExceptionalMobileAlbum: Array<IAlbum> = [];

  constructor(
    private lightbox: Lightbox
  ) { }

  ngOnInit(): void {
    this.projectData.emit({
      summary: `I worked at IBM for 20 years on a variety of projects from 
          desktop applications to hosted web applications.  These projects 
          are listed here with descriptions of the project, what I worked on,
          any interesting technologies used, and screenshots 
          (when available and applicable).`,
      projectLogo: 'client_ibm_logo.webp',
    });

    this.expediteAlbum.push(
      {
        thumb: 'assets/images/client_ibm_expedite_base_thumb.webp',
        src: 'assets/images/client_ibm_expedite_base.webp',
        caption: 'Original Expedite Base'
      },
      {
        thumb: 'assets/images/client_ibm_expedite_windows_pickup_thumb.webp',
        src: 'assets/images/client_ibm_expedite_windows_pickup.webp',
        caption: 'Expedite for Windows - Pickup'
      },
      {
        thumb: 'assets/images/client_ibm_expedite_windows_pickup_thumb.webp',
        src: 'assets/images/client_ibm_expedite_windows_address_book.webp',
        caption: 'Expedite for Windows - Address Book'
      },
      {
        thumb: 'assets/images/client_ibm_expedite_windows_pickup_thumb.webp',
        src: 'assets/images/client_ibm_expedite_windows_dropoff_boxes.webp',
        caption: 'Expedite for Windows - Dropoff Boxes'
      },
      {
        thumb: 'assets/images/client_ibm_expedite_windows_pickup_thumb.webp',
        src: 'assets/images/client_ibm_expedite_windows_receipts.webp',
        caption: 'Expedite for Windows - Receipts'
      },
      {
        thumb: 'assets/images/client_ibm_expedite_windows_pickup_thumb.webp',
        src: 'assets/images/client_ibm_expedite_windows_quick_send.webp',
        caption: 'Expedite for Windows - Quick Send'
      },
      {
        thumb: 'assets/images/client_ibm_expedite_windows_pickup_thumb.webp',
        src: 'assets/images/client_ibm_expedite_windows_session_status.webp',
        caption: 'Expedite for Windows - Status'
      }
    )   

    this.diAlbum.push(
      {
        thumb: 'assets/images/client_ibm_di_main_thumb.webp',
        src: 'assets/images/client_ibm_di_main.webp',
        caption: 'Data Interchange list window'
      },
      {
        thumb: 'assets/images/client_ibm_di_editor_thumb.webp',
        src: 'assets/images/client_ibm_di_editor.webp',
        caption: 'Data Interchange sample editor window'
      },
      {
        thumb: 'assets/images/client_ibm_di_map_thumb.webp',
        src: 'assets/images/client_ibm_di_map.webp',
        caption: 'Data Interchange sample map view window'
      },
    )

    this.biAlbum.push(
      {
        thumb: 'assets/images/client_ibm_bihub_AddUMIAccount-nwb_thumb.webp',
        src: 'assets/images/client_ibm_bihub_AddUMIAccount-nwb.webp',
        caption: 'BI Hub Administration Screens'
      },
      {
        thumb: 'assets/images/client_ibm_bihub_AddUMIAccount-nwb_thumb.webp',
        src: 'assets/images/client_ibm_bihub_MaintainUMIAccountsAndResources.webp',
        caption: 'BI Hub Administration Screens'
      },
      {
        thumb: 'assets/images/client_ibm_bihub_AddUMIAccount-nwb_thumb.webp',
        src: 'assets/images/client_ibm_bihub_CFB_ChangeFeature.webp',
        caption: 'BI Hub Administration Screens'
      },
      {
        thumb: 'assets/images/client_ibm_bihub_AddUMIAccount-nwb_thumb.webp',
        src: 'assets/images/client_ibm_bihub_AddAccountKeyMapping.webp',
        caption: 'BI Hub Administration Screens'
      }
    )

    this.fmAlbum.push(
      {
        thumb: 'assets/images/client_ibm_efm_home_thumb.webp',
        src: 'assets/images/client_ibm_efm_home.webp',
        caption: 'Home Page (standard IBM internal layout with left navigation)'
      },
      {
        thumb: 'assets/images/client_ibm_efm_pseries_thumb.webp',
        src: 'assets/images/client_ibm_efm_pseries.webp',
        caption: 'Selection of pSeries inventory group'
      },
      {
        thumb: 'assets/images/client_ibm_efm_autogen_thumb.webp',
        src: 'assets/images/client_ibm_efm_autogen.webp',
        caption: 'Selection of inventory group with custom date range'
      },
      {
        thumb: 'assets/images/client_ibm_efm_autogen_email_thumb.webp',
        src: 'assets/images/client_ibm_efm_autogen_email.webp',
        caption: 'Sample email response with reports as atachments'
      },
    )
   


  }

  open(album: Array<IAlbum>, index: number): void {
    // open lightbox
    this.lightbox.open(album, index);
  }


}
