<p class="indented"><a href="http://www.peepnote.com">PeepNote</a> allows people to import their twitter contacts and keep notes on them, including why they followed them, why they stopped following them, and other notes to help remember the important things about the people you meet online.</p>

<p class="indented">Below is the promotional video prepared for it:</p>

<div style="text-align: center;">

  <div id="media">
    <video height="500" width="640" autoplay loop="true">
    <source src="/assets/project/peepnote/Peepnote Tour.mp4" type="video/mp4">
  </video>
  </div>

</div>

