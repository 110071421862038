<h1>projects</h1>

<div class="indented">

	<hr style="border-width: 0 0 1px; color: #666;" />
	<a name="expEDIte"></a>

	<img style="float:right; border:0; padding-top: 45px; padding-left: 8px;"
		[src]="'/assets/images/client_ibm_expedite_windows_about_thumb.webp'" />

	<h3>Expedite</h3>

	<p class="indented">My first project at IBM was on a client which
		transferred free-format email and EDI messages to/from Information
		Exchange. It was called Expedite and I worked to port it from a
		simplistic console application that used text-based configuration
		files and was written in C, to a modern, object-oriented, C++
		Windows application with full GUI configuration.</p>

	<p class="indented">The old Expedite Base was a cross-platform product
		that worked on a wide variety of operating systems including AS/400,
		MS-Windows, MVS, OS/2, and UNIX (ex. AIX, SCO OpenServer, Linux).
		The new Expedite was also built with cross-platform support
		using <a href="http://www.xvt.com">XVT</a>, <a href="https://www.roguewave.com">Rogue
			Wave Tools++</a>, and <a href="https://www.raima.com">Raima</a></p>

	<div style="text-align: center;">
		<img style="vertical-align:middle" class="clickable" [src]="'/assets/images/client_ibm_expedite_base_thumb.webp'"
			(click)="open(expediteAlbum, 0)" />
		&mdash;to&rarr;
		<img style="vertical-align:middle" class="clickable"
			[src]="'/assets/images/client_ibm_expedite_windows_pickup_thumb.webp'" (click)="open(expediteAlbum, 1)" />
	</div>

	<hr style="border-width: 0 0 1px; color: #666;" />
	<a name="hpc"></a>

	<h3>Home Page Creator (HPC)</h3>

	<img src="/assets/images/client_ibm_hpc_jumpstart.jpg" style="float:right" />

	<p class="indented">Home Page Creator was an online web site creation
		tool. It was targeted as small businesses to allow them to create
		an online store that included product catalogs, a shopping cart, and
		the ability to process credit cards online. Created sites were then
		hosted on IBM servers.</p>

	<p class="indented">I worked on a project to re-factor the existing
		product into a Lotus-ASP (application service provider) based
		product. I was the technical lead of the registration and billing
		system which was to be built using web services and EJBs.</p>

	<p class="indented">This project successfully exited the planning
		phase, but was not implemented due to IBM's decision not to compete
		in the ASP market. However, initial prototyping and development was
		done using VisualAge for Java.</p>

	<hr style="border-width: 0 0 1px; color: #666;" />
	<a name="di"></a>

	<img style="float:right; border:0; padding-top: 45px; padding-left: 8px;"
		[src]="'/assets/images/client_ibm_di_about_thumb.webp'" />

	<h3>Data Interchange</h3>

	<p class="indented">Data Interchange performs <i>any-to-any</i>
		transformations of EDI, record-oriented, and XML application data
		formats. I worked on the Windows client which is used to setup the
		transformation mappings. I worked primarily to add the XML mapping
		support. The windows client was developed using Visual C++.</p>

	<div style="text-align: center; margin-top: 25px; clear: both;">
		<ng-container *ngFor="let image of diAlbum; let i=index">
			<img class="clickable" style="vertical-align: middle;" [ngStyle]="{'margin-left' : i > 0 ? '-60px' : 'default'}"
				[src]="image.thumb" (click)="open(diAlbum, i)" />
		</ng-container>
	</div>

	<p class="indented">I also developed a prototype web-based interface
		to replace the host-only (3270) interface used to setup and control
		the Data Interchange server. This prototype utilized XML
		configuration files to define the necessary UI form elements and
		associated validation for each form element in a generic way. This
		prototype used Java servlets and JSPs and was the concept later
		improved upon for my <a href="#srm">Server Resource Management</a>
		work.</p>

	<hr style="border-width: 0 0 1px; color: #666;" />
	<a name="bi"></a>

	<h3>Business Integration (BI) Hub</h3>

	<p class="indented">I lead the design and development of a J2EE
		application to be a central message processor for IBM Global
		Services billing. This message processor utilized a Message-Driven
		EJB to receive XML messages via JMS on MQ Series. The message
		processor processed the messages it received according to business
		rules defined in <i>message profiles</i> which included validation
		of the message against XML schemas, logging of all messages to DB2,
		other message-specific processing, and returned XML response
		messages via JMS on MQ Series.</p>

	<p class="indented">The message processor functioned as an ESB
		(enterprise service bus) in an SOA (service oriented architecture)
		supporting multiple messaging mechanisms such as request-response
		and store-and-forward. This application was ASCA certified and was
		developed using WebSphere Studio Application Developer. It was
		deployed to a environment consisting of WebSphere Application
		Server, DB/2, MQ Series, and WebSphere MQ Integrator.</p>

	<div style="float:right;margin-left: 20px;">
		<img style="vertical-align:middle" class="clickable"
			[src]="'/assets/images/client_ibm_bihub_AddUMIAccount-nwb_thumb.webp'" (click)="open(biAlbum, 0)" />
	</div>

	<p class="indented">In addition to the message processor, I also
		designed a web user interface based on XForms technology. The
		visual layout of these forms was very basic as it was only intended
		for a few administrators to see and use, however the use of XForms to
		determine form content and XML schemas for validation created a very
		flexible and robust interface.</p>

	<hr style="border-width: 0 0 1px; color: #666;clear:both" />
	<a name="fm"></a>

	<h3>Feature Matrix</h3>

	<p class="indented">The feature matrix is a representation of the
		model to feature code relationships for a set of products. Sales
		or support personnel select the particular model, or family of
		models, for which they need to see supported features. Some
		reports are available online while others are sent as email
		attachments.</p>

	<p class="indented">I helped migration the Feature Matrix system
		from a stand-alone PC program written in Pascal to an online J2EE
		system. I introduced XSL transformations into this new system in
		order to produce a variety of reports on common XML data set and
		thereby eliminated unnecessary database access and Java coding. I
		wrote the first XSL reports and then mentored the team for how to
		write XSL files for additional reports.</p>.

	<div style="text-align: center; margin-top: 8px;">
		<ng-container *ngFor="let image of fmAlbum; let i=index">
			<img class="clickable" style="vertical-align: top;" [ngStyle]="{'margin-left' : i > 0 ? '-60px' : 'default'}"
				[src]="image.thumb" (click)="open(fmAlbum, i)" />
		</ng-container>
	</div>

	<p class="indented">Sample
		Reports: <br />
		<a href="/assets/project/ibm/CHARGES.TXT">CHARGES.TXT</a>
		| <a href="/assets/project/ibm/EXTDEVICE.TXT">EXTDEVICE.TXT</a>
		| <a href="/assets/project/ibm/PRODNUM.TXT">PRODNUM.TXT</a>
		| <a href="/assets/project/ibm/XMLDATA.XML">XMLDATA.XML (raw data)</a>
	</p>

	<p class="indented">Sample XSL: <br />
		<a href="/assets/project/ibm/CHARGES.xsl">CHARGES.XSL</a>
		| <a href="/assets/project/ibm/EXTDEVICE.xsl">EXTDEVICE.XSL</a>
		| <a href="/assets/project/ibm/PRODNUM.xsl">PRODNUM.XSL</a>
		| <a href="/assets/project/ibm/autogen-common.xsl">autogen-common</a>
		| <a href="/assets/project/ibm/featconv-common.xsl">featconv-common</a>
	</p>


	<hr style="border-width: 0 0 1px; color: #666;" />
	<a name="srm"></a>

	<h3>Server Resource Management (SRM)</h3>

	<img style="float:right; border:0; padding-top: 45px; padding-left: 8px;"
		[src]="'/assets/images/client_ibm_srm_SRMbannerShortBlu_thumb.webp'" />

	<p class="indented">Server Resource Management (SRM) is an IBM
		Global Services web application that reports historical trends of
		key server resources, such as CPU, Memory, and Disk in support of
		customers worldwide.</p>

	<!-- ELITE? -->

	<p class="indented">I started out with SRM as the chief architect
		for the SRM 6.0 report viewer (shown below). This was a next
		generation interface which added dynamic data retrieval from an
		SOA via Ajax and rendered interactive SVG graphs and reports on
		the client side with JavaScript from raw XML data.</p>

	<div style="padding-left: 60px">
		<video height="500" width="640" autoplay loop="true">
			<source src="/assets/project/ibm/srm6.0-demo-640.mp4" type="video/mp4">
		</video>
	</div>

	<p class="indented">Following the report viewer, I architected and
		implemented a rich form builder for the account and server
		registration system. This form builder dynamically builds full
		featured interfaces that are complete with sophisticated field
		validation, complex field dependencies which can hide/show/disable
		other fields or complete form sections, provide field help, and
		retrieve field values and options dynamically via Ajax. New forms
		are defined with a simple XML file listing required fields and
		their dependencies.</p>

	<p class="indented">Subsequent to the report viewer, I was given
		the task of architecting a solution to migrate SRM from a pull
		system where data files were retrieved from each server each night
		using FTP/SFTP, to a push system were each server sends in their
		data using HTTPS. The delivery mechanism needed to randomly
		distribute the delivery time within each server's time zone,
		gracefully recover from errors, and retry at various intervals.
		Once successfully delivered by the customer server, the data was
		transfered to SRM through MQ queues to provide ensured delivery
		and scalable processing.</p>

	<p class="indented">In conjuction with the Push architecture, I
		built an error reporting application called <i>IBMExceptional</i>.
		This application is written
		in <a href="https://rubyonrails.org/">Ruby on Rails</a>, but in
		order to run in the IBM eco-system which
		means <a href="https://www.ibm.com/software/webservers/appserv/was/">WebSphere
			Application Server</a>
		and <a href="https://www.ibm.com/software/data/db2/">DB2</a>, it
		uses <a href="https://jruby.org/">JRuby</a> and is packaged as a
		WAR file
		with <a href="https://caldersphere.rubyforge.org/warbler/">Warbler</a>. It
		is similar in concept
		to <a href="https://w3.ibm.com/news/w3news/top_stories/2010/01/cl_us_w2ready_2010.html">Exceptional</a>
		for Ruby apps, except that it captures exception information from
		Java applications. It can either automatically record all
		exceptions that are subclassed for a provided IBMExceptional Java
		base class or alternatively includes
		a <a href="https://logging.apache.org/log4j/">log4j</a> adapter to
		record logged exceptions. I also
		used <a href="https://code.google.com/p/iui/">iui</a> to add a
		mobile interface to give support staff greater flexibility in
		responding to problems.</p>

	<div style="text-align: center; margin-top: 8px;">
		<img class="clickable" style="vertical-align: top;"
			[src]="'/assets/images/client_ibm_ibmexceptional_home_thumb.webp'" />
		<img class="clickable" style="vertical-align: top; margin-left: -60px;"
			[src]="'/assets/images/client_ibm_ibmexceptional_error_list_thumb.webp'" />
		<img class="clickable" style="vertical-align: top; margin-left: -60px;"
			[src]="'/assets/images/client_ibm_ibmexceptional_error_view_thumb.webp'" />
		<img class="clickable" style="vertical-align: top; margin-left: -60px;"
			[src]="'/assets/images/client_ibm_ibmexceptional_new_project_thumb.webp'" />
	</div>

	<div style="text-align: center; margin-top: 8px;">
		<img class="clickable" style="vertical-align: top;"
			[src]="'/assets/images/client_ibm_ibmexceptional_iphone_login_thumb.webp'" />
		<img class="clickable" style="vertical-align: top; margin-left: -40px;"
			[src]="'/assets/images/client_ibm_ibmexceptional_iphone_home_thumb.webp'" />
		<img class="clickable" style="vertical-align: top; margin-left: -40px;"
			[src]="'/assets/images/client_ibm_ibmexceptional_iphone_error_list_thumb.webp'" />
		<img class="clickable" style="vertical-align: top; margin-left: -40px;"
			[src]="'/assets/images/client_ibm_ibmexceptional_iphone_error_view_thumb.webp'" />
		<img class="clickable" style="vertical-align: top; margin-left: -40px;"
			[src]="'/assets/images/client_ibm_ibmexceptional_iphone_new_project_thumb.webp'" />
	</div>

	<p class="indented">With <i>IBMExceptional</i> in place
		demonstrating that I could run Ruby on Rails applications
		successfully in IBM under WebSphere connected to a DB2 database, I
		was able to leverage that knowledge for the next assignment I was
		asked to build concurrent to the SRM Push work. For this
		assignment I was to deliver a simple web interface to that
		provided a means for outlying servers, which needed to be
		accounted for in SRM but was unable/unwilling to run the full SRM
		collection agent, to record the essential utilzation
		metrics. Leveraging Rails, this application was quickly
		implemented and delivered (much faster than I could have done with
		Java which I am fully proficient with), it included both a
		standard web interface and a mobile web interface, and within 3
		months was already accounting for over 30,000 servers around the
		world.</p>

	<div style="text-align: center; margin-top: 8px;">
		<img class="clickable" style="vertical-align: top;" [src]="'/assets/images/client_ibm_srmai_home_thumb.webp'" />
		<img class="clickable" style="vertical-align: top; margin-left: -60px;"
			[src]="'/assets/images/client_ibm_srmai_list_thumb.webp'" />
		<img class="clickable" style="vertical-align: top; margin-left: -60px;"
			[src]="'/assets/images/client_ibm_srmai_view_thumb.webp'" />
		<img class="clickable" style="vertical-align: top; margin-left: -60px;"
			[src]="'/assets/images/client_ibm_srmai_details_thumb.webp'" />
	</div>

	<div style="text-align: center; margin-top: 8px;">
		<img class="clickable" style="vertical-align: top;"
			[src]="'/assets/images/client_ibm_srmai_iphone_home_thumb.webp'" />
		<img class="clickable" style="vertical-align: top; margin-left: 0px;"
			[src]="'/assets/images/client_ibm_srmai_iphone_list_thumb.webp'" />
	</div>

</div>