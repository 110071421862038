
<p class="indented">
  My contributions include adding the ability for administrators to
  edit listing (prior to that, all updates were either done directly
  in the database, or were dependant on the 3rd party data stream to
  be updated) as well as adding email support through various contact
  forms to send email to both users and administrators.
</p>

<h1>screenshots</h1>

<div style="text-align: center; margin-top: 50px;">
  <ng-container *ngFor="let image of album; let i=index">
    <img class="clickable" [ngStyle]="{'margin-left' : i > 0 ? '-30px' : 'default'}"
      [src]="image.thumb" (click)="open(i)" />
  </ng-container>
</div>