<div class="centeredContent">
  <div class="title">
    Steven Pothoven
  </div>
  <div class="paddedContent">
    <h1>profile</h1>
    <p></p>

    <p class="indented">
      <img src="assets/images/Steven Pothoven-suit.webp" alt="Steven Pothoven" style="float: left; padding-right: 20px;"
        height=200>
      I have been a Solutions Architect at <a href="https://usicllc.com">USIC</a> for the last {{currentJobYears}}
      years where I lead a development team in the development of both internal and external web applications which utilize
      <a href="https://angular.io">Angular</a> for the front-end and communicate to a <a
        href="https://jsonapi.org/">json:api</a> back-end API written in Java.
    </p>
    
    <p class="indented">Before that, I was the Lead Developer and Architect for <a
        href="https://intouchgps.com/">InTouchGPS</a> (now <a href="https://gpstrackit.com">GPSTrackIt</a>)
      for 3 years where we developed a <a href="https://rubyonrails.org">Ruby on Rails</a> application for fleet management.
    </p>
    
    <p class="indented">Prior to that I was an IT Specialist and IT Architect for
      <a href="https://www.ibm.com">IBM</a> for 20 years giving me extensive experience designing and developing
      software solutions. I hold a
      <a onclick="javascript:urchinTracker('/download/patent-US07062773__.pdf');"
        href="files/patent-US07062773__.pdf">patent</a>
      for some of my work, and am an
      <a href="https://www-03.ibm.com/certify/certs/01001503.shtml">IBM Certified Solution Developer in XML and Related
        Technologies</a>
      and an
      <a href="https://www-03.ibm.com/certify/certs/50001201.shtml">IBM Certified Solution Advisor - Cloud Computing
        Architecture V1</a>.
      I have lead projects through the entire process from requirement gathering to systems integration
      testing and have consistently stayed at the forefront of technology advances. 
    </p>

    <p class="indented">I have written professional applications in Java, Ruby on Rails,
      C/C++, Ada and dabbled in C#, PHP, and even Perl, but have more recently turned my interest toward
      web development - in particular JavaScript/TypeScript using frameworks such as
      Angular, Ember.js, and React. For additional details, please see my <a
        href="https://www.linkedin.com/in/pothoven/">LinkedIn profile</a>
    </p>

    <p class="indented">I received my Master of Science in Computer
      Science from the <a href="https://www.ucf.edu">University of
        Central Florida</a>. My <a href="https://pothoven.net/ucf">Master&apos;s
        project</a> and was part of the research information used as the basis for a
      later <a href="https://pothoven.net/workman/cen5016/Projects/TRED/TRED.html">course offering</a>
      and <a href="https://pothoven.net/workman/cen5016/Projects/TRED/IVIT04-TRED.pdf">publication</a>).
      I received my Bachelor in Science from <a href="https://www.calvin.edu">Calvin College</a> (now Calvin University).
    </p>

    <p class="indented">I have been married for {{ marriedYears }} years and have three adult
      children. I am also an officer in my church,
      <a href="https://www.christcentralpca.org/">Christ Central Presbyterian Church</a>
      (formerly known as Seminole Presbyterian Church).
    </p>

    <h1>project portfolio</h1>
    <span style="font-size: small; margin-left: 130px; vertical-align: text-top;"> (select a project to view)</span>

    <div style="text-align: center;">
      <img id="usic" class="clientLogo clickable" (click)="moveToTop($event)"
        src="assets/images/client_usic_logo.webp" title="Click to view project portfolio" />
      <img id="intouchgps" class="clientLogo clickable" (click)="moveToTop($event)"
        src="assets/images/client_intouchgps_logo.webp" title="Click to view project portfolio" />
      <img id="ibm" class="clientLogo clickable" (click)="moveToTop($event)" 
        src="assets/images/client_ibm_logo.webp" title="Click to view project portfolio" />
    </div>

    <div style="text-align: center;">
      <img id="seminole" class="clientLogo clickable" (click)="moveToTop($event)"
        src="assets/images/client_seminole_pca.webp" title="Click to view project portfolio" />
      <img id="christcentral" class="clientLogo clickable" (click)="moveToTop($event)"
        src="assets/images/client_christcentral_logo.webp" title="Click to view project portfolio" />
    </div>

    <div style="text-align: center;">
      <img id="stfrancis" class="clientLogo clickable" (click)="moveToTop($event)"
        src="assets/images/client_st_francis_society_logo.webp" title="Click to view project portfolio" />
      <img id="peepnote" class="clientLogo clickable" (click)="moveToTop($event)"
        src="assets/images/railsrumble_peepnote.webp" title="Click to view project portfolio" />
      <img id="commendablekids" class="clientLogo clickable" (click)="moveToTop($event)"
        src="assets/images/railsrumble_commendablekids.webp" title="Click to view project portfolio" />
    </div>

    <div style="text-align: center;">
      <img id="wombeat" class="clientLogo clickable" (click)="moveToTop($event)"
        src="assets/images/client_wombeat_logo.webp" title="Click to view project portfolio" />
      <img id="rrr" class="clientLogo clickable" (click)="moveToTop($event)"
        src="assets/images/client_ruby_rail_review.webp" title="Click to view project portfolio" />
      <img id="ciso" class="clientLogo clickable" (click)="moveToTop($event)" src="assets/images/client_ciso.webp"
        title="Click to view project portfolio" />
    </div>

    <h1>additional information</h1>

    <div class="indented">

      <table style="margin: 20px 0px;">
        <colgroup>
          <col width="156px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th rowspan="3" style="vertical-align:top">Social Networking</th>
            <td class="addInfo"><a href="https://www.linkedin.com/in/pothoven"><img
                  src="assets/images/icons/linkedin.webp" alt="" title="LinkedIn" border="0" height="24px" width="24px"
                  align="top" /> LinkedIn</a> (provides my resum&#233;)</td>
          </tr>
          <tr>
            <td class="addInfo"><a href="https://www.facebook.com/pothoven"><img src="assets/images/icons/facebook.webp"
                  alt="" title="Facebook" border="0" height="24px" width="24px" align="top" /> FaceBook</a></td>
          </tr>
          <tr>
            <td class="addInfo"><a href="https://twitter.com/pothoven"><img src="assets/images/icons/twitter.webp" alt=""
                  title="Twitter" border="0" height="24px" width="24px" align="top" /> Twitter</a></td>
          </tr>
          <tr>
            <th>Blog</th>
            <td class="addInfo"><a href="https://blog.pothoven.net"><img src="assets/images/icons/blogger.webp" alt=""
                  title="Blogger" border="0" height="24px" width="24px" align="top" /> The Pothoven Post</a></td>
          </tr>
          <tr>
            <th>Email</th>
            <td class="addInfo"><img src="assets/images/icons/email.webp" title="email" alt="" border="0" height="24px"
                width="24px" align="top" />
              <script type="text/javascript">
                /* <![CDATA[ */
                function hivelogic_enkoder() {
                  var kode =
                    "kode=\"oked\\\"=);''):-1thnglee.od(kAtarche.od?kthnglee.od<k(ix+e=od}ki)t(" +
                    "rAha.cdeko)++1(iAtarche.od=kx+){=2i+);-1thnglee.od(ki<0;i=r(fo';=';x\\\"\\" +
                    "\\)''(nioj.)(esrever.)''(tilps.edok=edok\\\"\\\\\\\\\\\\o;ek\\\\d\\\\\\\"=" +
                    "\\\\\\\\x\\\\\\\\e\\\\o=}dcke)o(rdhCmarC.onfrgSi+t;=2x=8c10+c)f<;(-ii3t)e(" +
                    "oArdhC.adckeco)=+{;+tinhlgeeo.<d;k=i(0oi;r'fx'\\\\=\\\\\\\\;\\\\\\\"\\\\\\" +
                    "\\\\\\\\\\\\\\\\\\\\\\\\\\,\\\\\\\\*\\\\q*r+1l+mv,hhhu1y*u+,l*sw1ogvnhhrr@" +
                    ">ggnf%prqx1huwwz+l_hd%k?h\\\#@u_i__d%oprlvwh=hw.yhqCzreksywqrqhw1_h__w%w#h" +
                    "l_o%@F_q_drwwVfh#hw#yrqkSywqr_h__v%hAhwCyrqksywqrqhw12hA?_d>%@,g%nh\\\\r\\" +
                    "\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"e\\\\o=\\\\d\\\\\\\"" +
                    "k\\\\\\\\;\\\\\\\\o\\\\ekkdd=.opeis(l't.'e)ersv(r.eo)nj'i)(='\\\"\\\\\\\\" +
                    "\\\\edok\\\"\\\\e=od\\\"kk;do=eokeds.lpti'()'r.verees)(j.io(n'')\";x='';fo" +
                    "r(i=0;i<(kode.length-1);i+=2){x+=kode.charAt(i+1)+kode.charAt(i)}kode=x+(i" +
                    "<kode.length?kode.charAt(kode.length-1):'');"
                    ; var i, c, x; while (eval(kode));
                } hivelogic_enkoder();
/* ]]> */
              </script>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h1>just for fun</h1>
    <p class="indented">Sometimes I need to take a break from serious work and just make a game. Here are a few
      chess-based online puzzles I wrote (I did not invent the games, just implemented them). </p>

    <div style="text-align: center;">
      <a href="https://www.pothoven.net/knightPuzzle.html"><img id="knightPuzzle"
          src="assets/images/chess-knight-black-small.webp" border=0 title="Knight Puzzle"
          class="clickable chessPiece" /></a>
      <a href="https://www.pothoven.net/knightPuzzle2.html"><img id="knightPuzzle2"
          src="assets/images/chess-knight-black-small.webp" border=0 title="Knight Puzzle 2"
          class="clickable chessPiece" /></a>
      <a href="https://www.pothoven.net/bishopPuzzle.html"><img id="bishopPuzzle"
          src="assets/images/chess-bishop-black-small.webp" border=0 title="Bishop Puzzle"
          class="clickable chessPiece" /></a>
      <a href="https://www.pothoven.net/queenPuzzle.html"><img id="queenPuzzle"
          src="assets/images/chess-queen-black-small.webp" border=0 title="Queen Puzzle"
          class="clickable chessPiece" /></a>
    </div>


  </div>
</div>

<div id="darkenBackground" style="display:none;">
  <!--  -->
</div>