import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Lightbox, IAlbum } from 'ngx-lightbox';
import { ProjectData } from '../project.component';

@Component({
  selector: 'app-commendablekids',
  templateUrl: './commendablekids.component.html',
  styleUrls: ['./commendablekids.component.scss']
})
export class CommendablekidsComponent implements OnInit {

  @Output() projectData: EventEmitter<ProjectData> = new EventEmitter<ProjectData>();

  album: Array<IAlbum> = [];

  constructor(
    private lightbox: Lightbox
  ) { }

  ngOnInit(): void {
    this.projectData.emit({
      summary: `<a href="http://www.commendablekids.com">Commendable Kids</a> was my entry
          into the <a href="http://r10.railsrumble.com/">2010 Rails Rumble</a> competition 
          with my team, <a href="http://r10.railsrumble.com/teams/agile-nomads">Agile Nomads</a>. `,
      projectLogo: 'railsrumble_commendablekids.webp',
    });

    this.album.push(
      {
        thumb: 'assets/images/railsrumble_commendablekids_home_thumb.webp',
        src: 'assets/images/railsrumble_commendablekids_home.webp',
        caption: 'Home Page'
      },
      {
        thumb: 'assets/images/railsrumble_commendablekids_badges_thumb.webp',
        src: 'assets/images/railsrumble_commendablekids_badges.webp',
        caption: 'Available Badges'
      },
      {
        thumb: 'assets/images/railsrumble_commendablekids_awarded_thumb.webp',
        src: 'assets/images/railsrumble_commendablekids_awarded.webp',
        caption: 'Awarded Badges to a Child'
      },
      {
        thumb: 'assets/images/railsrumble_commendablekids_badge_thumb.webp',
        src: 'assets/images/railsrumble_commendablekids_badge.webp',
        caption: 'Sample Badges for a Child'
      },
    )

  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.album, index);
  }

}
