import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { ProjectData } from '../project.component';

@Component({
  selector: 'app-stfrancis',
  templateUrl: './stfrancis.component.html',
  styleUrls: ['./stfrancis.component.scss']
})
export class StfrancisComponent implements OnInit {

  @Output() projectData: EventEmitter<ProjectData> = new EventEmitter<ProjectData>();

  album: Array<{ src: string, caption: string, thumb: string }> = [];

  constructor(
    private lightbox: Lightbox
  ) {
  }

  ngOnInit(): void {
    this.projectData.emit({
      summary: `The <a href="http://www.stfrancisrescue.org">St. Francis Society Animal
          Rescue</a> wanted a web-based application to both manage and
          advertise the animals in their care.  I worked to provide them with
          a solution that added much needed efficiencies, allowing them to
          keep full records on each animal, respond more quickly to public
          inquiries and calls for help, as well as putting them in a better
          position to take advantage of grant-writing opportunities. Their
          volunteers have quickly embraced the solution that helped them adopt
          710 cats and dogs in 2008.`,
      projectLogo: 'client_st_francis_society_logo.webp',
    })

    this.album.push(
      {
        thumb: 'assets/images/client_st_francis_society_home_thumb.webp',
        src: 'assets/images/client_st_francis_society_home.webp',
        caption: 'Home Page'
      },
      {
        thumb: 'assets/images/client_st_francis_society_cats_thumb.webp',
        src: 'assets/images/client_st_francis_society_cats.webp',
        caption: 'Section of the cat listing page'
      },
      {
        thumb: 'assets/images/client_st_francis_society_cats_shadowbox_thumb.webp',
        src: 'assets/images/client_st_francis_society_cats_shadowbox.webp',
        caption: 'Full size animal pictures displayed in shadowbox'
      },
      {
        thumb: 'assets/images/client_st_francis_society_admin_dashboard_thumb.webp',
        src: 'assets/images/client_st_francis_society_admin_dashboard.webp',
        caption: 'Administration dashboard'
      },
      {
        thumb: 'assets/images/client_st_francis_society_admin_cat_list_thumb.webp',
        src: 'assets/images/client_st_francis_society_admin_cat_list.webp',
        caption: 'Animal listings and other site data can be edited online'
      },
      {
        thumb: 'assets/images/client_st_francis_society_admin_cat_list_with_pictures_thumb.webp',
        src: 'assets/images/client_st_francis_society_admin_cat_list_with_pictures.webp',
        caption: 'Multiple pictures can be uploaded for each animal'
      },
      {
        thumb: 'assets/images/client_st_francis_society_admin_cat_health_thumb.webp',
        src: 'assets/images/client_st_francis_society_admin_cat_health.webp',
        caption: 'Full medical health records can be maintained and printed for each animal'
      },
      {
        thumb: 'assets/images/client_st_francis_society_admin_cat_filter_thumb.webp',
        src: 'assets/images/client_st_francis_society_admin_cat_filter.webp',
        caption: 'Administration includes dynamic filters'
      },
      {
        thumb: 'assets/images/client_st_francis_society_admin_page_editor_thumb.webp',
        src: 'assets/images/client_st_francis_society_admin_page_editor.webp',
        caption: 'WSYWIG HTML editor for page content'
      }
    )

  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.album, index);
  }


}
