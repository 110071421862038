<div id="body_top" class="centeredContent">
  <div class="rotate270 outsideTopLeft"><a href="/" title="Return to main page">Steven Pothoven</a></div>
  <table *ngIf="ready">
    <tr>
      <td valign="top">
        <img class="clientLogo" src="/assets/images/{{ (projectData | async)?.projectLogo }}" />
      </td>
      <td>
        <p style="margin: 0 30px; font-family: Verdana" innerHTML="{{(projectData | async)?.summary}}"></p>
      </td>
    </tr>
  </table>

  <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate()"></router-outlet>

</div>