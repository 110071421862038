import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Lightbox, IAlbum } from 'ngx-lightbox';
import { ProjectData } from '../project.component';

@Component({
  selector: 'app-usic',
  templateUrl: './usic.component.html',
  styleUrls: ['./usic.component.scss']
})
export class UsicComponent implements OnInit {


  @Output() projectData: EventEmitter<ProjectData> = new EventEmitter<ProjectData>();

  album: Array<IAlbum> = [];

  constructor(
    private lightbox: Lightbox
  ) { }

  ngOnInit(): void {
    this.projectData.emit({
      summary: `At USIC (United States Infrastructure Company) I am a solutions architect and am responsible for
        a number of web-based applications including an asset manager, a job advancement tracker, 
        a customer portal for accesing ongoing work and damages, an internal messenger, and a general reporting tool.`,
      projectLogo: 'client_usic_logo.webp',
    });  
  
    this.album.push(
      {
        thumb: 'assets/images/client_usic_apps.webp',
        src: 'assets/images/client_usic_apps.webp',
        caption: 'Landing page with user entitled applications'
      },
      {
        thumb: 'assets/images/client_usic_ats_dashboard.webp',
        src: 'assets/images/client_usic_ats_dashboard.webp',
        caption: 'Asset Tracking System dashboard'
      },
      {
        thumb: 'assets/images/client_usic_ats_asset_list.webp',
        src: 'assets/images/client_usic_ats_asset_list.webp',
        caption: 'List of assets'
      },
      {
        thumb: 'assets/images/client_usic_ats_edit_asset.webp',
        src: 'assets/images/client_usic_ats_edit_asset.webp',
        caption: 'Dialog to view/edit an asset'
      },
      {
        thumb: 'assets/images/client_usic_careerprogression_jobs.webp',
        src: 'assets/images/client_usic_careerprogression_jobs.webp',
        caption: 'Requirements for job level promotions'
      },
      {
        thumb: 'assets/images/client_usic_careerprogression_report.webp',
        src: 'assets/images/client_usic_careerprogression_report.webp',
        caption: 'Career Progression results report'
      },
      {
        thumb: 'assets/images/client_usic_cp_summary.webp',
        src: 'assets/images/client_usic_cp_summary.webp',
        caption: 'Customer Portal landing page'
      },
      {
        thumb: 'assets/images/client_usic_cp_closedtickets.webp',
        src: 'assets/images/client_usic_cp_closedtickets.webp',
        caption: 'Customer Portal list of closed tickets (with map visible)'
      },
      {
        thumb: 'assets/images/client_usic_cp_searchmap.webp',
        src: 'assets/images/client_usic_cp_searchmap.webp',
        caption: 'Customer Portal search for tickets within a radius'
      },
      {
        thumb: 'assets/images/client_usic_cp_ticketdetails.webp',
        src: 'assets/images/client_usic_cp_ticketdetails.webp',
        caption: 'Customer Portal view of ticket details'
      },
      {
        thumb: 'assets/images/client_usic_cp_tickettext.webp',
        src: 'assets/images/client_usic_cp_tickettext.webp',
        caption: 'Customer Portal view of original 811 ticket text'
      },
      {
        thumb: 'assets/images/client_usic_cp_ticketphotos.webp',
        src: 'assets/images/client_usic_cp_ticketphotos.webp',
        caption: 'Customer Portal view of photos taken by locator for the ticket'
      },
      {
        thumb: 'assets/images/client_usic_cp_ticketvideos.webp',
        src: 'assets/images/client_usic_cp_ticketvideos.webp',
        caption: 'Customer Portal view of videos taken by locator for the ticket'
      },
      {
        thumb: 'assets/images/client_usic_cp_ticketattachments.webp',
        src: 'assets/images/client_usic_cp_ticketattachments.webp',
        caption: 'Customer Portal view of additional attachments for the ticket'
      },
      {
        thumb: 'assets/images/client_usic_cp_damages.webp',
        src: 'assets/images/client_usic_cp_damages.webp',
        caption: 'Customer Portal list of damages'
      },
      {
        thumb: 'assets/images/client_usic_cp_damagephotos.webp',
        src: 'assets/images/client_usic_cp_damagephotos.webp',
        caption: 'Customer Portal view of photos taken by damage claim investigator'
      },
      {
        thumb: 'assets/images/client_usic_messenger_inbox.webp',
        src: 'assets/images/client_usic_messenger_inbox.webp',
        caption: 'Messenger list of messages received'
      },
      {
        thumb: 'assets/images/client_usic_messenger_read.webp',
        src: 'assets/images/client_usic_messenger_read.webp',
        caption: 'Messenger read a message'
      },
      {
        thumb: 'assets/images/client_usic_messenger_manager.webp',
        src: 'assets/images/client_usic_messenger_manager.webp',
        caption: 'Messenger list of composed messages'
      },
      {
        thumb: 'assets/images/client_usic_messenger_edit.webp',
        src: 'assets/images/client_usic_messenger_edit.webp',
        caption: 'Messenger compose a message'
      },
      {
        thumb: 'assets/images/client_usic_messenger_send.webp',
        src: 'assets/images/client_usic_messenger_send.webp',
        caption: 'Messenger send a message'
      },
    )
  
  }

  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.album, index);
  }


}
